import React, { useRef, useState,useEffect } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import Layout from "../components/layout";
//import ReactPinterestTag from "react-pinterest-tag";

// import GoogleAds from "../components/googleads";

import "../assets/css/main.css";

const query = graphql`
  query {
    strapiHomepage {
      hero {
        title
      }
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;


export default function Gracias() {

  const data = useStaticQuery(query);
  // useEffect(() => {
  //   ReactPinterestTag.init('2613435780576');
  //   ReactPinterestTag.pageView();
  //   ReactPinterestTag.track("lead");
  //   console.log("aqui");
  //   console.log("aqui");
  // }, []);

  return (
    <Layout seo={data.strapiHomepage.seo}>
    <div className="mb-20 col-span-1 py-32">
        <div className="max-w-5xl mx-auto text-center px-4 sm:px-6 lg:px-8 ">
            <h1 className="max-w-3xl m-auto poppins pt-12 md:text-5xl text-4xl leading-11 pb-2 text-morado font-bold">¡Bienvenida!</h1>
            <h3 className="text-center poppins text-2xl m-auto pb-6 max-w-xl leading-9 pt-2">Mira tu bandeja de entrada 📥 acabamos de enviarte un email con todas las instrucciones.</h3>
            <p className="text-center poppins text-base m-auto pb-12 max-w-xl leading-7 pt-0">Si no lo encuentras, busca en la carpeta de SPAM o en PROMOCIONES. El email puede tardar hasta 5 minutos en llegar</p>
          </div>
          <div className="max-w-5xl mx-auto py-12 px-4 sm:px-6 lg:py-4 lg:px-8 flex justify-center ">
            </div>
        </div>
    </Layout>
  )
}